:root {
  font-size: 16px;
}

.font-base-header-700-2 {
  font-size: 2.875rem;
  /* 46px */
  line-height: 3.75rem;
  /* 60px */
  font-weight: 700;
}

.font-base-header-700-md {
  font-size: 1.625rem;
  /* 26px */
  line-height: 2.438rem;
  /* 39px */
  font-weight: 700;
}

.font-base-header-700-md {
  font-size: 1.625rem;
  /* 20px */
  line-height: 1.75rem;
  /* 39px */
  font-weight: 700;
}

.font-base-copy-body-700 {
  font-size: 1.12rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
  font-weight: 700;
}

.font-base-header-700-8 {
  font-weight: 700;
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.87rem;
  /* 30px */
}

/* 600 */
.font-base-header-2 {
  font-size: 2.875rem;
  /* 46px */
  line-height: 3.75rem;
  /* 60px */
  font-weight: 600;
}

.font-base-header-6 {
  font-size: 1.625rem;
  /* 26px */
  line-height: 2.438rem;
  /* 39px */
  font-weight: 600;
}

.font-base-header-7 {
  font-size: 1.5rem;
  /* 24px */
  font-weight: 700;
  line-height: 2rem;
  /* 32px */
}

.font-base-header-8 {
  font-weight: 600;
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.87rem;
  /* 30px */
}

.font-base-subtitle-2 {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
  font-weight: 400;
}

/* Bold L */
.font-base-copy-bold-xl {
  font-size: 1.12rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
  font-weight: 700;
}

.font-base-copy-bold-l {
  font-size: 1.12rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
  font-weight: 600;
}

/* Body L */
.font-base-copy-bold-500-l {
  font-size: 1.12rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
  font-weight: 500;
}

/* Bold M */
.font-base-copy-bold-m {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
  font-weight: 700;
}

/* Body M */
.font-base-copy-body-m {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
  font-weight: 400;
}

.font-base-copy-body-500-m {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
  font-weight: 500;
}

/* Bold S */
.font-base-copy-bold-s {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.375rem;
  /* 22px */
  font-weight: 600;
}

/* Body S */
.font-base-copy-body-s {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.375rem;
  /* 22px */
  font-weight: 500;
}

.font-base-caption-bold {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1.12rem;
  /* 18px */
  font-weight: 600;
}

/* Components/Button M */
.font-components-button-m {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
  font-weight: 500;
}

/* Components/Button S */
.font-components-button-s {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.375rem;
  /* 22px */
  font-weight: 500;
}

/* Components/Button XS */
.font-components-button-xs {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1.12rem;
  /* 18px */
  font-weight: 500;
}

@media (min-width: 1540px) {
  .font-base-header-2 {
    font-size: 3.375rem;
    /* 46px */
    line-height: 5rem;
    /* 60px */
  }

  .font-base-copy-bold-s {
    font-size: 1rem;
    /* 14px */
    line-height: 1.5rem;
    /* 22px */
    font-weight: 600;
  }

  .font-base-header-6 {
    font-size: 1.75rem;
    /* 28px */
    line-height: 2.625rem;
    /* 42px */
  }

  .font-base-header-700-md {
    font-size: 1.625rem;
    /* 26px */
    line-height: 2.438rem;
    /* 39px */
  }

  .font-base-copy-body-s,
  .font-base-copy-body-m {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
  }

  .font-base-copy-body-l {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
  }

  .font-base-copy-bold-l {
    font-size: 1.75rem;
    /* 28px */
    line-height: 2.625rem;
    /* 42px */
    font-weight: 600;
  }
}

@media (max-width: 992px) {
  :root {
    font-size: 14px;
  }
}


/* uppercase */
.font-uppercase {
  text-transform: uppercase;
}

/* lowercase */
.font-lowercase {
  text-transform: lowercase;
}

/* capitalize */
.font-capitalize {
  text-transform: capitalize;
}