// Mixin for link styles
@mixin link-colors($color) {
  color: $color;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: darken($color, 15%);
  }

  &:focus {
    outline: none;
    color: darken($color, 15%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

@each $name, $color in $colors-map {
  .link-#{$name} {
    @include link-colors($color);
  }
}
