.tooltip-container {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: 175px;
    background-color: var(--grayscale-c9);
    color: var(--white);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
