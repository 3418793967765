button:not([disabled]) {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.bg-position-center {
  background-position: center;
}

.image-container {
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
}

.inline-flex {
  display: inline-flex;
}

.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.break-word {
  word-wrap: break-word;
  width: 100%;
}

/* Tiny size */
.text-tiny {
  font-size: 12px;
}

/* Small size */
.text-small {
  font-size: 14px;
}

/* Default size */
.text-default {
  font-size: 16px;
}

/* Big size */
.text-big {
  font-size: 18px;
}

/* Huge size */
.text-huge {
  font-size: 24px;
  font-weight: bold;
}
