.header-dropdown {
  position: relative;
  cursor: pointer;
  margin-right: 0;

  .header-dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--black);
    min-width: 160px;
    border-radius: 0.375rem;
    border: 1px solid var(--grayscale-c9);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 1;
    top: 45px;
    list-style: none;
    padding: 8px 4px;
    right: 0;

    a {
      color: var(--grayscale-c6);
      text-decoration: none;
      display: block;
      padding: 8px 16px;

      &:hover,
      &.selected {
        color: var(--white);
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.29);
        border-radius: 8px;
        background:
          linear-gradient(var(--black), var(--black)) padding-box,
          linear-gradient(
              to left top,
              var(--yellow-gradient),
              var(--black-gradient)
            )
            border-box;
        border: 1px solid transparent;
      }
    }
  }
}

.active .header-dropdown-content {
  display: block;
}
