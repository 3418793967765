/* You can add global styles to this file, and also import other style files */
@import 'video.js/dist/video-js.css';
@import "./theme/frontend-theme.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;

  &.noScroll {
    overflow: hidden;
  }
}

html * {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Montserrat', sans-serif;
    color: var(--black);
  }
}

.video-js .vjs-loading-spinner {
  display: none;
}

.video-js .vjs-text-track-display .vjs-text-track-cue {
  div {
    background-color: rgba(0, 0, 0, 0.5) !important;
    font-size: 0.7em !important;
  }
}