@mixin button-style($background, $color) {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: $background;
  color: $color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-wrap: nowrap;

  &:hover,
  &:active {
    background-color: darken($background, 10%);
  }

  &:disabled {
    background-color: map-get($colors-map, "grayscale");
    color: map-get($colors-map, "black");
    cursor: not-allowed;
  }
}

@mixin icon-button-style($background, $icon-color) {
  @include button-style($background, $icon-color);
  width: 40px;
  height: 40px;
  padding: 10px;
}

@mixin rounded-button-style($background, $color) {
  @include button-style($background, $color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin outlined-button-style($border, $background, $color) {
  @include button-style($background, $color);
  border: $border;

  &:hover {
    background-color: $color;
    color: var(--white);
  }
}

@mixin outlined-rounded-icon-button-style($border, $background, $color) {
  @include icon-button-style($background, $color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: $border;

  &:hover {
    background-color: $color;
    color: var(--white);
  }
}

@mixin outlined-icon-button-style($border, $background, $color) {
  @include icon-button-style($background, $color);
  border: $border;

  &:hover {
    background-color: $color;
    color: var(--white);
  }
}

@each $name, $color in $colors-map {
  $button-selector: unquote(".button-#{$name}");
  $icon-button-selector: unquote(".icon-button-#{$name}");
  $rounded-button-selector: unquote(".rounded-button-#{$name}");

  #{$button-selector} {
    @include button-style($color, var(--white));
  }

  #{$icon-button-selector} {
    @include icon-button-style($color, var(--white));
  }

  #{$rounded-button-selector} {
    @include rounded-button-style($color, var(--white));
  }

  #{$button-selector}-outline {
    @include outlined-button-style(1px solid $color, transparent, $color);
  }

  #{$icon-button-selector}-outline {
    @include outlined-icon-button-style(1px solid $color, transparent, $color);
  }

  #{$rounded-button-selector}-outline {
    @include outlined-rounded-icon-button-style(1px solid $color, transparent, $color);
  }
}