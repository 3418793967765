.chips {
  padding: 5px;
  border-radius: 8px;
  border: 1px solid var(--grayscale-c7);
  background: var(--grayscale-c8);
  text-align: center;
}

.chips-card {
  background: linear-gradient(
    326deg,
    var(--yellow-gradient) 0%,
    var(--yellow-gradient) 8%,
    rgb(237 172 21 / 88%) 100%
  );
  padding: 2px;
  border-radius: 500px;

  & .chips-card-content {
    background: var(--grayscale-c10);
    border-radius: 500px;
    padding: 10px;
  }
}
