.progress-container {
  width: 100%;
  background: var(--grayscale-c9);
  border-radius: 12px;

  .progress {
    height: 4px;
    background: var(--primary);
    border-radius: 12px;
  }
}