$colors-map: (
  "white": #ffffff,
  "black": #181818,
  "grayscale": #bfbfbf,
  "primary": #edac15,
  "secondary": #e3121f,
  "danger": #bb2124,
  "success": #22bb33,
  "info": #5bc0de,
  "theme-bg": #0f0f0f,
  "grayscale-c10": #1a1a1a,
  "grayscale-c9": #313131,
  "grayscale-c8": #111,
  "grayscale-c7": #262626,
  "grayscale-c6": #999,
  "grayscale-c5": #1f1f1f,
  "grayscale-c3": #a7a7a7,
  "grayscale-c2": #e8e8e8,
  "black-gradient": rgba(237, 172, 21, 0),
  "yellow-gradient": rgba(237, 172, 21, 1),
  "secondary-gradient": rgba(227, 18, 31, 1),
);

:root {
  @each $name, $color in $colors-map {
    --#{$name}: #{$color};
  }
}

$colors: "white", "black", "grayscale", "primary", "secondary", "danger",
  "success", "info", "theme-bg", "grayscale-c2", "grayscale-c9", "grayscale-c6",
  "grayscale-c7", "grayscale-c10", "grayscale-c5", "secondary-gradient";

@each $color in $colors {
  .#{$color} {
    color: var(--#{$color});
  }

  .bg-#{$color} {
    background-color: var(--#{$color});
  }
}