.card {
  border-radius: 15px;
  background: linear-gradient(322deg,
      #000 1.06%,
      rgba(0, 0, 0, 0.6) 56.62%,
      rgb(227 18 31 / 27%) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.info-card {
  border-radius: 12px;
  background: var(--grayscale-c10);
  border: 1px solid var(--grayscale-c7);
  padding: 16px;
  color: var(--grayscale-c2);
}

.error-card {
  border-radius: 12px;
  background: var(--grayscale-c10);
  border: 1px solid var(--secondary-gradient);
  padding: 16px;
  color: var(--grayscale-c2);
  width: 330px;
}