.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .input,
  .select-input {
    height: 50px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--grayscale-c9);
    background: var(--grayscale-c8);
    padding: 14px;
    color: var(--grayscale-c3);
    position: relative;
  }
}

.error-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;

  .header-dropdown-content {
    position: absolute;
    background-color: var(--black);
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid var(--grayscale-c9);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 1;
    list-style: none;
    padding: 8px 4px;
    right: 0;

    a {
      color: var(--grayscale-c6);
      text-decoration: none;
      display: block;
      padding: 8px 16px;

      &:hover {
        color: var(--white);
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.29);
        border-radius: 8px;
        background:
          linear-gradient(var(--black), var(--black)) padding-box,
          linear-gradient(
              to left top,
              var(--yellow-gradient),
              var(--black-gradient)
            )
            border-box;
        border: 1px solid transparent;
      }
    }
  }
}

.required::after {
  content: "*";
  margin: 0 5px;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
