.header-dropdown {
  position: relative;
  cursor: pointer;
  margin-right: 0;

  .notification-dropdown-content {
    position: absolute;
    background-color: var(--black);
    min-width: 420px;
    max-height: 470px;
    overflow: auto;
    overscroll-behavior: contain;
    border-radius: 0.5rem;
    border: 1px solid var(--grayscale-c9);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 1;
    top: 45px;
    padding: 20px;
    right: 0;

    .notification-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .notification-image {
        display: flex;
        padding: 8px;
        border-radius: 10px;
        border: 1px solid var(--grayscale-c7);
        opacity: 0.6;
        background: var(--grayscale-c10);
        img {
          width: 90px;
          height: 52.081px;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .no-notification-image {
        display: flex;
        padding: 8px;
        border-radius: 10px;
        border: 1px solid var(--grayscale-c7);
        opacity: 0.6;
        background: var(--grayscale-c10);
        width: 148px;
        height: 66px;
        align-items: center;
        justify-content: center;
        img {
          width: 33.33px;
          height: 33.33px;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .notification-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .text-bold-s {
          font-size: 0.875rem;
          line-height: 1.375rem;
          font-weight: 600;
        }
      }
    }
  }
}

.active .notification-dropdown-content {
  display: block;
}

@media screen and (max-width: 768px) {
  .header-dropdown {
    .notification-dropdown-content {
      position: fixed;
      top: 65px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

@media screen and (max-width: 420px) {
  .header-dropdown {
    .notification-dropdown-content {
      .notification-description {
        flex-direction: column;
        justify-content: center !important;
        align-items: flex-start !important;
      }
    }
  }
}
